<template>

  <div
    class="card"
    style="border: none;"
    :id="isPrint ? 'print' : ''"
  >
    <div
      class="card-body"
      :style="isPrint ? `height: ${height};` : ''"
    >
      <div class="row justify-content-between align-items-center">
        <div class="col-8">
          <table>
            <div style="font-size: 14pt"><strong>Invoice</strong></div>
            <tr>
              <td style="width: 100px">Nomor</td>
              <td style="width: 9px"> : </td>
              <td> {{ data.id }}</td>
            </tr>
            <tr>
              <td>Tanggal</td>
              <td style="width: 9px"> : </td>
              <td> {{ data.display_created_at }}</td>
            </tr>
            <tr>
              <td>Nama Pasien</td>
              <td style="width: 9px"> : </td>
              <td> {{ data.patient_name }}</td>
            </tr>
            <tr>
              <td>Nomor HP</td>
              <td style="width: 9px"> : </td>
              <td> {{ data.patient_mobile_phone }}</td>
            </tr>
            <tr>
              <td>Dokter</td>
              <td style="width: 9px"> : </td>
              <td> {{ data.doctor_name }}</td>
            </tr>
          </table>
        </div>
        <div class="col">
          <ImageComponent place="logo-invoice"></ImageComponent>
          <div
            class="font-weight-light"
            style="font-size: 10.7px"
          >{{ setting.clinic_address }}</div>
          <div
            class="font-weight-light"
            style="font-size: 10.7px"
          >{{ setting.phone }}</div>
        </div>
      </div>
      <div style="min-height: 285px">
        <div class="row">
          <div class="col-md-12">
            <b-table
              striped
              hover
              small
              :items="items"
              :fields="fields"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                />
              </template>
              <template #cell(price)="data">
                {{ 'Rp ' + parseInt(parseInt(data.item.price) + data.item.discount_amount).toLocaleString('id-ID') }}
              </template>
              <template #cell(discount)="data">
                <span>Rp {{ `${parseInt(data.item.discount_amount).toLocaleString('id-ID')} ${(data.item.discount_type == 1 && (data.item.discount_amount != 0 || data.item.discount_percentage != 0)) ? `(${parseInt(data.item.discount_percentage)})%` : ''}` }}</span>
              </template>
              <template #cell(subtotal)="data">
                {{ 'Rp ' + parseInt(data.item.price * data.item.quantity).toLocaleString('id-ID') }}
              </template>
            </b-table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 py-0">
            <span class="font-weight-bold d-block">Catatan :</span>
          </div>
          <div class="col-md-12 pt-1">
            <div style="width: 300px;height: 90px;border: 2px solid rgb(196, 196, 196);border-radius: 7px;padding: 5px;overflow: hidden;">
              <span>{{data.notes}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="mr-2">
          <!-- Notes -->
          <!-- <div
            class="form-group"
          >
            <label for="notes">Catatan:</label>
            <textarea
              name=""
              id="notes"
              cols="30"
              rows="3"
              class="form-control"
              style="resize: none"
              v-model="data.notes"
              readonly
            ></textarea>
          </div> -->

        </div>
        <table v-if="showSummary">
          <tr>
            <td style="width: 150px">Subtotal</td>
            <td
              class="font-weight-bold"
              style="width: 25px"
            >Rp</td>
            <td class="text-right"><span class="font-weight-bold">{{ parseInt(data.total_amount).toLocaleString('id-ID') }}</span></td>
          </tr>
          <tr>
            <td>Diskon</td>
            <td
              class="font-weight-bold"
              style="width: 25px"
            >Rp</td>
            <td class="text-right"><span class="font-weight-bold">{{ parseInt(data.discount).toLocaleString('id-ID') }}</span></td>
          </tr>
          <tr>
            <td>Biaya Tambahan</td>
            <td
              class="font-weight-bold"
              style="width: 25px"
            >Rp</td>
            <td class="text-right"><span class="font-weight-bold">{{ parseInt(data.surcharge).toLocaleString('id-ID') }}</span></td>
          </tr>
          <tr>
            <td>Total</td>
            <td
              class="font-weight-bold"
              style="width: 25px"
            >Rp</td>
            <td class="text-right"><span class="font-weight-bold">{{ parseInt(data.final_amount).toLocaleString('id-ID') }}</span></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="d-flex justify-content-center p-0 mt-n3">
      <div>
        <hr
          class="special-hr"
          style="margin: 2px"
        >
        <div>{{ currentUserPersonalInfo.name }}</div>
      </div>
    </div>
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import ImageComponent from "@/component/general/ImageComponent.vue";

export default {

  props: {
    data: Object,
    height: String,
    isPrint: Boolean,
    setting: Object,
    items: Array,
    renderNotes: Boolean,
    showSummary: Boolean,
    notes: String
  },

  computed: {

    ...mapGetters(["currentUserPersonalInfo"]),

    mutated_total_amount: {
      set(newValue) {
        this.data.total_amount = newValue
      },
      get() {
        let amount = this.data.total_amount
        this.items.forEach(value => {
          amount += (value.discount_amount * value.quantity)
        })
        return amount
      }
    },

    mutated_final_amount: {
      set(newValue) {
        this.data.final_amount = newValue
      },
      get() {
        let amount = this.data.final_amount
        this.items.forEach(value => {
          amount += (value.discount_amount * value.quantity)
        })
        return amount
      }
    },

  },

  components: {
    ImageComponent
  },

  data() {
    return {
      // Table Head
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "name",
          label: 'Keterangan',
        },
        {
          key: "quantity",
          label: 'Jumlah',
        },
        {
          key: "discount",
          label: 'Diskon',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: "price",
          label: 'Harga',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: "subtotal",
          label: 'Subtotal',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
      ]
    }
  },

  mounted() {
    console.log(this.data, "notes");
  }

}
</script>

<style scoped>
.special-hr {
  border-color: #000000;
  border-style: solid;
  border-width: 2px 0 0;
  clear: both;
  margin: 0 0 0px;
  height: 0;
}
</style>