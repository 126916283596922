<template>
  <div class="row">

    <div class="col-md-8">
      <Print
        :data="data"
        height="297mm"
        :setting="setting"
        :showSummary="true"
        :items="items"
        :renderNotes="renderNotes"
      />
    </div>

    <div class="d-none">
      <Print
        :data="data"
        height="297mm"
        :isPrint="true"
        :setting="setting"
        :showSummary="true"
        :items="items"
        :renderNotes="renderNotes"
        :notes="data.notes"
      />
      <!-- <div
        v-if="items.length > 10"
        id="print"
      >
        <Print
          v-for="(n, index) in new Array(papers).fill(null)"
          :data="data"
          height="170mm"
          :isPrint="true"
          :key="index"
          :setting="setting"
          :showSummary="(index + 1) == itemsMultiPapers.length"
          :items="itemsMultiPapers[index]"
          :renderNotes="renderNotes"
          :notes="data.notes"
        />
    </div> -->
  </div>

  <!-- PDF -->
  <vue-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="true"
    :preview-modal="true"
    :paginate-elements-by-height="1400"
    :filename="'struk-transaksi-' + data.patient_name"
    :pdf-quality="2"
    :manual-pagination="false"
    pdf-format="a4"
    pdf-orientation="portrait"
    @progress="onProgress($event)"
    @hasStartedGeneration="hasStartedGeneration()"
    @hasGenerated="hasGenerated($event)"
    ref="html2Pdf"
  >
    <section slot="pdf-content">
      <Print        
        :data="data"
        height="285mm"
        :isPrint="true"
        :setting="setting"
        :showSummary="true"
        :items="items"
        :renderNotes="renderNotes"
        :notes="data.notes"
      />
      <!-- <template v-if="items.length > 10">
        <Print
          v-for="(n, index) in new Array(papers).fill(null)"
          :data="data"
          height="143.8mm"
          :isPrint="true"
          :key="index"
          :setting="setting"
          :showSummary="(index + 1) == itemsMultiPapers.length"
          :items="itemsMultiPapers[index]"
          :renderNotes="renderNotes"
          :notes="data.notes"
        />
      </template> -->
    </section>
  </vue-html2pdf>

  <div class="col-md">
    <b-button
      squared
      class="mt-2"
      size="sm"
      variant="primary"
      @click="btnPrintOnClick"
    >Print</b-button>
    <b-button
      squared
      :class="'d-block mt-2'"
      size="sm"
      variant="info"
      @click="btnDownloadOnClick"
    >Download</b-button>
    <!-- <b-button
        v-if="data.payment_status_id != 3 && data.payment_status_id != 4"
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="success"
        @click="$router.push('/payment/edit/' + data.id)"
      >Edit Pembayaran</b-button> -->
    <b-button
      v-if="data.payment_status_id != 3 && data.payment_status_id != 4"
      squared
      :class="'d-block mt-2'"
      size="sm"
      variant="success"
      @click="$router.push('/payment/edit/' + data.id)"
    >Tambah Pembayaran Baru</b-button>

    <div class="mt-5">
      <h3 v-if="payment_slips.length != 0">Pembayaran</h3>
      <div
        class="card shadow-sm my-3"
        v-for="(item, index) in payment_slips"
        :key="item.id"
        @click="$router.push(`/payment/slip-detail/${item.payment_id}/${item.id}?terms=${index + 1}`)"
      >
        <div class="mx-3 py-3">
          <div class="d-flex align-items-center justify-content-between">
            <span>Pembayaran #{{ index + 1 }}</span>
            <span
              v-if="item.payment_status != 3"
              class="font-weight-bold text-hover-success"
            >Rp {{ parseInt(item.payment_amount).toLocaleString('id-ID') }}</span>
            <span
              v-if="item.payment_status == 3"
              class="font-weight-bold text-hover-success"
            >Rp {{ parseInt(item.payment_amount - item.change_amount).toLocaleString('id-ID') }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <small class="text-primary">{{ new Date(item.date).toLocaleString('id-ID', { day: 'numeric', month: '2-digit', year: 'numeric' }).replaceAll('/', '-') }} - <span class="font-weight-bold">{{ item.payment_method_name }}</span></small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <h3>Kekurangan</h3>
      <div class="card shadow-sm my-3">
        <div class="mx-3 py-3">
          <div class="d-flex align-items-center justify-content-end">
            <span class="font-weight-bold text-danger">Rp {{ parseInt(data.unpaid_amount).toLocaleString('id-ID') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>

<script>

import Print from '@/component/payments/Print.vue'
import VueHtml2pdf from "vue-html2pdf"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Print,
    VueHtml2pdf
  },

  data() {
    return {
      // Data
      data: {},
      payment_slips: [],
      items: [],
      itemsMultiPapers: [],
      papers: 1,
      // access management
      manipulateBtn: false,
      // setting
      setting: {},
      // other
      renderNotes: true
    }
  },

  methods: {

    async getSetting() {
      this.setting = await module.get(`settings/${1}`)
    },

    async getPayment() {
      this.data = await module.get(`payments/${this.$route.params.id}`)
      this.payment_slips = await module.get(`payment-slips/get-by-payment/${this.$route.params.id}`)
      // If Data Not found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/payment/list')
      }
    },

    async getPaymentDetail() {
      this.items = await module.list(`payment-details/${this.$route.params.id}`)
      this.papers = Math.ceil(this.items.length / 10)
      let start = 0
      for (let j = 0; j < this.papers; j++) {
        this.itemsMultiPapers.push(this.items.slice(start, start + 10))
        start += 10
      }
    },

    btnPrintOnClick() {
      this.renderNotes = false
      this.$htmlToPaper('print')
      this.renderNotes = true
    },

    async btnDownloadOnClick() {
      this.renderNotes = false
      await this.$refs.html2Pdf.generatePdf()
      this.renderNotes = true
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6002") {
          this.manipulateBtn = true
        }
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Keuangan" },
      { title: "Pembayaran", route: "/payment/list" },
      { title: "Detail" },
    ])
    // Get Data
    this.getPayment()
    this.getSetting()
    this.getPaymentDetail()
    this.setActiveMenu()
  },

}
</script>

<style>
</style>